<template>
  <div class="info">
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">咨询</span>
        </template>
      </van-cell>
      <van-field label="问题来源" readonly clearable clear-trigger="always" v-model="dataForm.guideTitle" />
      <van-field label="咨询问题" readonly clearable clear-trigger="always" v-model="dataForm.questionTitle" />
      <van-field label="问题时间" readonly clearable clear-trigger="always" v-model="dataForm.questionTime" />
      <van-field clearable clear-trigger="always" :border="false" class="left" v-model="dataForm.questionContent" rows="3"
        autosize type="textarea" placeholder="请输入问题描述" input-align="left" readonly />
      <van-cell>
        <template #title>
          <div style="display:flex">
            <upload-file :file-list="faqsFileList" :del-btn-show="false" :upload-btn-show="false"  />
          </div>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group style="margin-top: 10px" v-if="answerShow">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">回复</span>
        </template>
      </van-cell>
      <van-field label="回复人" readonly clearable clear-trigger="always" v-model="dataForm.answerUserStr" />
      <van-field label="回复时间" readonly clearable clear-trigger="always" v-model="dataForm.answerTime" />
      <van-field clearable clear-trigger="always" :border="false" class="left" v-model="dataForm.answerContent" rows="3"
        autosize type="textarea" placeholder="请输入回复描述" input-align="left" readonly />
      <van-cell>
        <template #title>
          <div style="display:flex">
            <upload-file :file-list="answerFileList" :del-btn-show="false" :upload-btn-show="false" />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UploadFile from '@/components/upload/uploadFile'
import { getImageStream } from '@/utils'
export default {
  components: {
    UploadFile
  },
  data() {
    return {
      answerShow: false,
      faqsFileList: [],
      answerFileList: [],
      dataForm: {
        guideTitle: '',
        questionTitle: '',
        questionTime: '',
        questionContent: '',
        answerUserStr: '',
        answerTime: '',
        answerContent: ''
      }
    }
  },
  computed: { ...mapState(['guideId']) },
  created () {
    let id = this.$route.query.id
    this.getInfo(id)
  },
  methods: {
    upload (files) {
      this.fileList = files
      this.newFileMessages = files.filter(item => !item.id)
    },
    getInfo (id) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/commonApp/guide/faqs/info"),
        method: "post",
        params: this.$http.adornParams({
          id: parseInt(id)
        }, false),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm = data.commonAppGuideFaqs
          this.answerShow = data.commonAppGuideFaqs.hasAnswer
          if (data.commonAppGuideFaqs.faqsFileList && data.commonAppGuideFaqs.faqsFileList.length > 0) {
            data.commonAppGuideFaqs.faqsFileList.map((item, index) => {
              let u
              if (item.type == "1") {
                u = getImageStream(item.filePath)
              } else if(item.type == "2") {
                u = item.url
              } else {
                u = getImageStream("files/wx/images/content/" + item.type + ".png")
              }
              let url = {
                uid: index,
                id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: item.type,
                url: u,
                relativePath: item.filePath.replace(/\\/g, "/")
              }
              console.log(url)
              if (item.isSolve == 0) {
                this.faqsFileList.push(url)
              } else if (item.isSolve == 1) {
                this.answerFileList.push(url)
              }
            })
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>